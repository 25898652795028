<template>
    <div class="flex items-center gap-1 self-stretch pb-6">
        <p class="text-[20px] font-semibold leading-[32px] text-[#18181B] font-inter">Summary</p>
    </div>
    <div class="flex justify-between items-center self-stretch py-3 border-t border-b border-dashed border-[#D4D4D8]">
        <p class="text-[#27272A] font-inter text-[16px] font-normal leading-8">Monthly License (Pro)</p>
        <p class="text-[#09090B] text-right font-inter text-[16px] font-medium leading-8">$18.00 USD</p>
    </div>
    <div class="flex justify-between items-center self-stretch py-3 border-b border-dashed border-[#D4D4D8]">
        <p class="text-[#27272A] font-inter text-[16px] font-normal leading-8">Pro-rated Discount</p>
        <p class="text-[#09090B] text-right font-inter text-[16px] font-medium leading-8">-$9.00 USD</p>
    </div>
    <div class="flex justify-between items-center self-stretch py-3 border-b border-dashed border-[#D4D4D8]">
        <p class="text-[#27272A] font-inter text-[16px] font-normal leading-8">Discount</p>
        <p class="text-[#09090B] text-right font-inter text-[16px] font-medium leading-8">-$5.00 USD</p>
    </div>
    <div class="flex justify-between items-center self-stretch py-3 border-b border-dashed border-[#D4D4D8]">
        <p class="text-[#27272A] font-inter text-[16px] font-semibold leading-[32px]">Due Today</p>
        <p class="text-[#09090B] text-right font-inter text-[16px] font-normal leading-8">$0.00 USD</p>
    </div>
    <div class="flex justify-between items-center self-stretch py-3 border-b border-dashed border-[#D4D4D8]">
        <p class="text-[#27272A] font-inter text-[16px] font-semibold leading-[32px]">Due on February 11,2025</p>
        <p class="text-[#09090B] text-right font-inter text-[16px] font-normal leading-8">$18.00 USD</p>
    </div>
    <div class="relative w-full flex items-center pt-[36px] pb-[19px]">
    <div class="absolute inset-x-0 border-t divider"></div>
</div>
</template>
<style lang="scss" scoped>
.divider {
    border: 1px solid var(--Zinc-300, #d4d4d8);
}
</style>
