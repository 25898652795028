import { defineStore } from 'pinia';
import { ref } from 'vue';

// Pricing plan interface
export interface PricingPlan {
    term: 'monthly' | '1year' | '2year' | '3year';
    essential: string;
    pro: string;
    signature: string;
}

export const useSubscriptionModalStore = defineStore('subscription-modal', () => {
    // Filter exposures where is_me is null or false
    const pricingPlans = ref<PricingPlan[]>([
        {
            term: 'monthly',
            essential: '$8.00 USD',
            pro: '$16.00 USD',
            signature: '$24.00 USD',
        },

        {
            term: '1year',
            essential: '$80.00 USD',
            pro: '$160.00 USD',
            signature: '$240.00 USD',
        },

        {
            term: '2year',
            essential: '$160.00 USD',
            pro: '$320.00 USD',
            signature: '$480.00 USD',
        },

        {
            term: '3year',
            essential: '$240.00 USD',
            pro: '$480.00 USD',
            signature: '$720.00 USD',
        },
    ]);

    const selectedPlan = ref<PricingPlan | null>(pricingPlans.value[0]);

    const benefits = {
        essentials: [
            '919+ Data Brokers & People Search Sites covered',
            'Mostly Automated removals, with human support',
            'Unlimited emails, phones, addresses, and alt. names',
            'Flexible Priority lower cost for non-urgent removals',
            'Continuous Monitoring with full monthly re-scans',
            'Real-Time Dashboard reporting on current efforts',
            'Monthly Email Report summarizing your progress',
        ],
        pro: [
            'Everything in Essentials',
            '178,274 Custom Sites covered for data removals',
            'Google, Yahoo, and Bing search monitoring',
            'Search Result Removal submitted on all deletions',
            'Social Media Listening critical doxxing protection',
            'Paste Site Coverage monitoring and removals',
            'Dark Web Monitoring for high-risk data breaches',
            'Mass-Marketing Opt-Out from major spam lists',
            'Blur My House on Google Maps and Apple Maps',
        ],
        signature: [
            'Everything in Essentials',
            'Priority Expedited Removals for faster takedowns',
            'US-Based Analysts exclusively handling removals',
            'Complex Takedowns requiring custom effort',
            'Legal Options reserved for maximum success rates',
            '24/7/365 SOC Escalation for critical exposures',
        ],
    };

    return {
        // state
        selectedPlan,
        pricingPlans,
        benefits,
    };
});
