<template>
    <div class="main-header h-[68px]">
        <div class="plan-section">
            <p class="text-[#18181B] font-inter text-[20px] font-semibold leading-[32px]">Select Plan</p>
        </div>
        <div class="h-[36px]">
            <PrimeTabs class="h-[36px]" value="0" @update:value="onTabChange">
                <PrimeTabList>
                    <PrimeTab v-for="tab in tabs" :key="tab.title" :value="tab.value">{{ tab.title }}</PrimeTab>
                </PrimeTabList>
            </PrimeTabs>
        </div>
    </div>
</template>
<script setup>
import { useSubscriptionModalStore } from '~/stores/components/modals/subscription-modal';

const tabs = [
    { title: 'Monthly', value: '0' },
    { title: '1 Year', value: '1' },
    { title: '2 Years', value: '2' },
    { title: '3 Years', value: '3' },
];

const store = useSubscriptionModalStore();

//handle tab change
const onTabChange = (value) => {
    console.log(value);

    store.selectedPlan = store.pricingPlans[value];
};
</script>
<style lang="scss" scoped>
.main {
    border-color: red;
}
.main-header {
    display: flex;
    padding-bottom: 24px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}
.plan-section {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}
</style>
