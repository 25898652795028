<template>
    <essentials :selectedPlan="selectedPlan" @update:selectedPlan="updateSelectedPlan" />
    <pro :selectedPlan="selectedPlan" @update:selectedPlan="updateSelectedPlan" />
    <signature :selectedPlan="selectedPlan" @update:selectedPlan="updateSelectedPlan" />
</template>
<script setup>
import Essentials from './SubscriptionPlans/Essentials.vue';
import Pro from './SubscriptionPlans/Pro.vue';
import Signature from './SubscriptionPlans/Signature.vue';

const selectedPlan = ref('Pro');

const updateSelectedPlan = (plan) => {
    selectedPlan.value = plan;
};
</script>
<style lang="scss" scoped>
.essential-plan {
    display: flex;
    padding: 24px 32px;
    align-items: flex-start;
    gap: 16px 32px;
    align-self: stretch;
    align-content: flex-start;
    border: 1px solid var(--Zinc-300, #d4d4d8);
}
.custom-button {
    background: var(--Blue-50, #f0f6fe);
    color: #3b82f6;
    border: 1px solid var(--Blue-200, #c3dbfc);
    border-radius: 16px;
    font-weight: 600;
    padding: 2px 10px;
    font-size: 14px;
    width: 71px;
    height: 24px;
}
</style>
